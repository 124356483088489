import React, { useCallback, useState } from 'react';
import { Basket } from '@/svg';
import { cn } from '@/lib/utils';
import { formatProductCount } from '@/lib/cart';
import { Dialog, DialogTrigger, Text } from '@/components/ui';
import { useCartStorageEvent } from '@/hooks/cartHook';
import { useIsClient } from '@/hooks/ssr';
import DealerDialogContent from '@/components/ui/cart/dialog/DealerDialogContent.tsx';

export type ButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & { referral?: string; loggedIn?: boolean };

const Cart: React.FC<ButtonProps> = ({ className, referral, loggedIn, ...rest }) => {
    const [productCount, setProductCount] = useState<string | null>(formatProductCount());

    const handleStorageEvent = useCallback(() => {
        setProductCount(formatProductCount());
    }, []);

    useCartStorageEvent(handleStorageEvent);

    const isClient = useIsClient();

    if (!referral && !loggedIn) {
        return (
            <Dialog>
                <DialogTrigger
                    className={cn(
                        'relative inline-flex size-14 items-center justify-center rounded-full border border-white bg-white bg-opacity-15 p-2',
                        className,
                    )}
                >
                    {isClient && productCount && (
                        <div className="bg-danger-medium absolute left-0 top-0 flex min-h-6 min-w-6 items-center justify-center rounded-full p-1">
                            <Text.Body bold className="text-xs text-white ">
                                {productCount}
                            </Text.Body>
                        </div>
                    )}
                    <Basket />
                </DialogTrigger>
                <DealerDialogContent redirect={rest.href} />
            </Dialog>
        );
    }

    return (
        <a
            {...rest}
            className={cn(
                'relative inline-flex size-14 items-center justify-center rounded-full border border-white bg-white bg-opacity-15 p-2',
                className,
            )}
        >
            {isClient && productCount && (
                <div className="bg-danger-medium absolute left-0 top-0 flex min-h-6 min-w-6 items-center justify-center rounded-full p-1">
                    <Text.Body bold className="text-xs text-white ">
                        {productCount}
                    </Text.Body>
                </div>
            )}
            <Basket />
        </a>
    );
};

export default Cart;
